<template>
  <div class="container-product pos-r">
    <div
      class="mask-buttons pos-a top-125-negative right-15"
      v-if="user?.role?.id != '188AEA750CF'"
    >
      <button
        v-if="!product.published && user?.role?.id === '188AEA7CD44'"
        class="btn btn-success mr-4"
        @click="speichern"
      >
        <font-awesome-icon icon="fa-solid fa-floppy-disk" />
        <span class="ml-2">Save product</span>
      </button>
      <button
        v-if="!product.published && user?.role?.id === '188AEA7CD44'"
        class="btn btn-dark-blue mr-4"
        @click="oeffnePublishModal"
      >
        <font-awesome-icon icon="fa-solid fa-check-circle" />
        <span class="ml-2">Publish product</span>
      </button>
      <button class="btn btn-dark-blue mr-4" @click="openQRModal">
        <font-awesome-icon icon="fa-solid fa-qrcode" />
        <span class="ml-2">Generate QR-Codes</span>
      </button>
      <button
        class="btn btn-primary mr-4"
        @click="oeffneCopyModal"
        v-if="user?.role.id === '188AEA7CD44'"
      >
        <font-awesome-icon icon="fa-solid fa-copy" />
        <span class="ml-2">Copy product</span>
      </button>
      <button
        class="btn btn-danger-light"
        @click="oeffneLoeschenModal"
        v-if="user?.role?.id === '188AEA7CD44'"
      >
        <font-awesome-icon icon="fa-solid fa-trash" />
        <span class="ml-2">Delete product</span>
      </button>
    </div>
    <div class="productBanner"></div>
    <div class="row">
      <div
        class="col-3 mr-auto product-card product-info d-flex flex-column justify-content-center align-items-center"
      >
        <div class="fs-65 mb-2">
          <font-awesome-icon icon="fa-regular fa-basket-shopping" />
        </div>
        <div class="fs-18 mb-2">
          <span class="text-bold uppercase">{{ product?.name }}</span>
        </div>
      </div>

      <div class="col-8 ml-auto d-flex flex-column product-card">
        <div class="row px-3 py-2">
          <div class="uppercase fs-14 text-grey">General</div>
        </div>

        <div class="row fs-14 px-2 mt-3 mb-1">
          <div class="col-6">
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                placeholder="Name"
                v-model="product.name"
                :disabled="product.published || user?.role?.id != '188AEA7CD44'"
              />
              <label>Product name</label>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                placeholder="Published"
                :value="getPublishedValue"
                disabled="true"
              />
              <label>Status</label>
            </div>
          </div>
        </div>

        <div class="row fs-14 px-2 mb-1">
          <div class="col-12">
            <div class="form-group">
              <v-select
                v-model="product.category"
                :options="filteredSuggestions"
                :multiple="true"
                label="name"
                @search="filterSuggestions"
                placeholder="Tags"
                :searchable="false"
                :disabled="product.published || user?.role?.id != '188AEA7CD44'"
              ></v-select>
              <label>Tags</label>
            </div>
          </div>
        </div>

        <div class="row fs-14 px-2 mb-1">
          <div
            class="col-6"
            v-if="!product.published && user?.role?.id === '188AEA7CD44'"
          >
            <div class="form-group">
              <input
                type="hidden"
                v-model="product.weight"
                :disabled="product.published"
              />
              <label class for="slider">Weight</label>
              <div class="mt-2 d-flex align-items-center vue-slider-input">
                <vue-slider
                  id="slider"
                  class="col-9 mr-3 slider-component"
                  v-model="product.weight"
                  :min="0"
                  :max="1000"
                  :disabled="product.published"
                ></vue-slider>
                <input
                  class="col-2 p-0 slider-value text-dark-blue"
                  type="number"
                  v-model.number="product.weight"
                  :disabled="product.published"
                />
              </div>
            </div>
          </div>
          <div
            class="col-6"
            v-if="product.published || user?.role?.id != '188AEA7CD44'"
          >
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                placeholder="Name"
                v-model="product.weight"
                disabled="true"
              />
              <label>Weight</label>
            </div>
          </div>

          <div
  class="col-3"
  v-if="!product.published && user?.role?.id === '188AEA7CD44'"
>
  <div class="form-group">
    <input type="hidden" v-model="product.weightunit" />
    <label class for="temperature-select">Unit</label>
    <select id="temperature-select" class="form-control" v-model="product.weightunit">
      <option value="18EEC3D96A6">grams</option>
      <option value="18EEC3DA9F1">pounds</option>
    </select>
  </div>
</div>

          <div
  class="col-3"
  v-if="!product.published && user?.role?.id === '188AEA7CD44'"
>
  <div class="form-group">
    <input type="hidden" v-model="product.temperature" />
    <label class for="temperature-select">Target temperature</label>
    <select id="temperature-select" class="form-control" v-model="product.temperature">
      <option value="18EEC2DE64A">warm</option>
      <option value="18EEC2E4986">lukewarm</option>
    </select>
  </div>
</div>

          <div
            class="col-6"
            v-if="product.published || user?.role?.id != '188AEA7CD44'"
          >
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                placeholder="Name"
                v-model="product.temperature"
                disabled="true"
              />
              <label>Target temperature [°C]</label>
            </div>
          </div>
        </div>

        <div class="row fs-14 px-2">
          <div class="col-12">
            <div class="form-group">
              <textarea
                class="form-control h-115-px"
                type="text"
                placeholder="Description"
                v-model="product.description"
                :disabled="product.published || user?.role?.id != '188AEA7CD44'"
              />
              <label>Description</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4 mb-5">
      <div class="col-12 d-flex flex-column productinfo-card">
        <div class="row p-3">
          <div class="uppercase fs-14 text-grey">Product Information</div>
        </div>
        <!--
        <div class="row mt-1 mb-3 fs-14 p-2">
           <div
            class="col-4"
            v-if="!product.published && user?.role?.id === '188AEA7CD44'"
          >
            <div class="form-group">
              <input
                type="hidden"
                v-model="product.weight"
                :disabled="product.published"
              />
              <label class for="slider">Weight [g]</label>
              <div class="mt-2 d-flex align-items-center vue-slider-input">
                <vue-slider
                  id="slider"
                  class="col-9 mr-3 slider-component"
                  v-model="product.weight"
                  :min="0"
                  :max="1000"
                  :disabled="product.published"
                ></vue-slider>
                <input
                  class="col-2 p-0 slider-value text-dark-blue"
                  type="number"
                  v-model.number="product.weight"
                  :disabled="product.published"
                />
              </div>
            </div>
          </div>
          <div
            class="col-4"
            v-if="product.published || user?.role?.id != '188AEA7CD44'"
          >
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                placeholder="Name"
                v-model="product.weight"
                disabled="true"
              />
              <label>Weight [g]</label>
            </div>
          </div> -->
        <!-- <div
            class="col-4"
            v-if="!product.published && this.user.role.id === '188AEA7CD44'"
          >
            <div class="form-group">
              <input type="hidden" v-model="product.temperature" />
              <label class for="slider">Target temperature [°C]</label>
              <div class="mt-2 d-flex align-items-center vue-slider-input">
                <vue-slider
                  id="slider"
                  class="col-9 mr-3 slider-component"
                  v-model="product.temperature"
                  :min="0"
                  :max="350"
                ></vue-slider>
                <input
                  class="col-2 p-0 slider-value text-dark-blue"
                  type="number"
                  v-model.number="product.temperature"
                />
              </div>
            </div>
          </div>
          <div
            class="col-4"
            v-if="product.published || user?.role?.id != '188AEA7CD44'"
          >
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                placeholder="Name"
                v-model="product.temperature"
                disabled="true"
              />
              <label>Target temperature [°C]</label>
            </div>
          </div>
        </div>-->

        <div class="row mt-1 mb-3 fs-14 p-2">
          <div class="col-4">
            <div class="form-group">
              <label>Mixing direction:</label>
              <v-select
                v-model="product.qrcode.mir"
                :searchable="false"
                :options="mirOptions"
                label="name"
                :reduce="(option) => option.value"
                placeholder="Select mixing direction"
                :disabled="product.published || user?.role?.id != '188AEA7CD44'"
              />
            </div>
          </div>
          <div
            class="col-4"
            v-if="!product.published && user?.role?.id === '188AEA7CD44'"
          >
            <div class="form-group">
              <input type="hidden" v-model="product.qrcode.miz" />
              <label class for="slider">Mixing time per direction [s]</label>
              <div class="mt-2 d-flex align-items-center vue-slider-input">
                <vue-slider
                  id="slider"
                  class="col-9 mr-3 slider-component px-3"
                  v-model="product.qrcode.miz"
                  :min="0"
                  :max="10"
                ></vue-slider>
                <input
                  class="col-2 p-0 slider-value text-dark-blue"
                  type="number"
                  v-model.number="product.qrcode.miz"
                />
              </div>
            </div>
          </div>
          <div
            class="col-4"
            v-if="product.published || user?.role?.id != '188AEA7CD44'"
          >
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                placeholder="Name"
                v-model="product.qrcode.miz"
                disabled="true"
              />
              <label>Mixing time per direction [s]</label>
            </div>
          </div>

          <div
            class="col-4"
            v-if="!product.published && user?.role?.id === '188AEA7CD44'"
          >
            <div class="form-group">
              <input type="hidden" v-model="product.qrcode.mip" />
              <label class for="slider"
                >Mixer PWM [%] (Mixer force/mixer speed)</label
              >
              <div class="mt-2 d-flex align-items-center vue-slider-input">
                <vue-slider
                  id="slider"
                  class="col-9 mr-3 slider-component px-3"
                  v-model="product.qrcode.mip"
                  :min="0"
                  :max="100"
                ></vue-slider>
                <input
                  class="col-2 p-0 slider-value text-dark-blue"
                  type="number"
                  v-model.number="product.qrcode.mip"
                />
              </div>
            </div>
          </div>
          <div
            class="col-4"
            v-if="product.published || user?.role?.id != '188AEA7CD44'"
          >
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                placeholder="Name"
                v-model="product.qrcode.mip"
                disabled="true"
              />
              <label>Mixer PWM [%] (Mixer force/mixer speed)</label>
            </div>
          </div>
        </div>

        <div class="row mt-1 mb-3 fs-14 p-2">
          <div
            class="col-4"
            v-if="!product.published && user?.role?.id === '188AEA7CD44'"
          >
            <div class="form-group">
              <input type="hidden" v-model="product.qrcode.wfp" />
              <label class for="slider">Total amount of water [ml]</label>
              <div class="mt-2 d-flex align-items-center vue-slider-input">
                <vue-slider
                  id="slider"
                  class="col-9 mr-3 slider-component"
                  v-model="product.qrcode.wfp"
                  :min="20"
                  :max="100"
                ></vue-slider>
                <input
                  class="col-2 p-0 slider-value text-dark-blue"
                  type="number"
                  v-model.number="product.qrcode.wfp"
                />
              </div>
            </div>
          </div>
          <div
            class="col-4"
            v-if="product.published || user?.role?.id != '188AEA7CD44'"
          >
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                placeholder="Name"
                v-model="product.qrcode.wfp"
                disabled="true"
              />
              <label>Total amount of water [ml]</label>
            </div>
          </div>

          <div
            class="col-4"
            v-if="!product.published && user?.role?.id === '188AEA7CD44'"
          >
            <div class="form-group">
              <input type="hidden" v-model="product.qrcode.wbp" />
              <label class for="slider"
                >Amount of water until the 1st pause [ml]</label
              >
              <div class="mt-2 d-flex align-items-center vue-slider-input">
                <vue-slider
                  id="slider"
                  class="col-9 mr-3 slider-component"
                  v-model="product.qrcode.wbp"
                  :min="20"
                  :max="100"
                ></vue-slider>
                <input
                  class="col-2 p-0 slider-value text-dark-blue"
                  type="number"
                  v-model.number="product.qrcode.wbp"
                />
              </div>
            </div>
          </div>
          <div
            class="col-4"
            v-if="product.published || user?.role?.id != '188AEA7CD44'"
          >
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                placeholder="Name"
                v-model="product.qrcode.wbp"
                disabled="true"
              />
              <label>Amount of water until the 1st pause [ml]</label>
            </div>
          </div>

          <div
            class="col-2"
            v-if="!product.published && user?.role?.id === '188AEA7CD44'"
          >
            <div class="form-group w-93-p">
              <input type="hidden" v-model="product.qrcode.zdp" />
              <label class for="slider">Pause duration [s]</label>
              <div class="mt-2 d-flex align-items-center vue-slider-input">
                <vue-slider
                  id="slider"
                  class="col-6 mr-3 slider-component"
                  v-model="product.qrcode.zdp"
                  :min="5"
                  :max="20"
                ></vue-slider>
                <input
                  class="col-3 ml-auto p-0 slider-value text-dark-blue"
                  type="number"
                  v-model.number="product.qrcode.zdp"
                />
              </div>
            </div>
          </div>
          <div
            class="col-2"
            v-if="product.published || user?.role?.id != '188AEA7CD44'"
          >
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                placeholder="Name"
                v-model="product.qrcode.zdp"
                disabled="true"
              />
              <label>Pause duration [s]</label>
            </div>
          </div>

          <div
            class="col-2"
            v-if="!product.published && user?.role?.id === '188AEA7CD44'"
          >
            <div class="form-group w-93-p">
              <input type="hidden" v-model="product.qrcode.adp" />
              <label class for="slider">Number of pauses</label>
              <div class="mt-2 d-flex align-items-center vue-slider-input">
                <vue-slider
                  id="slider"
                  class="col-6 mr-3 slider-component"
                  v-model="product.qrcode.adp"
                  :min="0"
                  :max="5"
                ></vue-slider>
                <input
                  class="col-3 ml-auto p-0 slider-value text-dark-blue"
                  type="number"
                  v-model.number="product.qrcode.adp"
                />
              </div>
            </div>
          </div>
          <div
            class="col-2"
            v-if="product.published || user?.role?.id != '188AEA7CD44'"
          >
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                placeholder="Name"
                v-model="product.qrcode.adp"
                disabled="true"
              />
              <label>Number of pauses</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Generate-q-r-modal id="generate-qr-modal" @generate="generateQR" />
    <message-modal
      id="loeschen-modal"
      :title="'Delete product'"
      :text="'Are you sure you want to delete this product?'"
      :okText="'Delete product'"
      :okColor="'btn-danger-light'"
      @ok="deleteProduct"
    />
    <message-modal
      id="message-modal"
      :title="'Publish product'"
      :text="'Are you sure you want to publish this product?'"
      :okText="'Publish product'"
      :okColor="'btn-dark-blue'"
      @ok="publishProduct"
    />

    <message-modal
      id="copy-message-modal"
      :title="'Copy product'"
      :text="'Are you sure you want to copy this product?'"
      :okText="'Copy product'"
      :okColor="'btn-dark-blue'"
      @ok="copyProduct"
    />
    <loading-overlay v-if="loading"></loading-overlay>
  </div>
</template>

<script>
import store from "@/store";

import Api from "@/Api";

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import GenerateQRModal from "@/components/Modals/GenerateQRModal";
import MessageModal from "@/components/Modals/MessageModal";
import LoadingOverlay from "@/components/global/LoadingOverlay";

export default {
  name: "Product",
  components: {
    GenerateQRModal,
    VueSlider,
    MessageModal,
    LoadingOverlay
  },
  mixins: [],
  store,
  metaInfo() {
    return {};
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      suggestions: [
        { id: 1, name: "Vegetarian" },
        { id: 2, name: "Vegan" },
        { id: 3, name: "Gluten-free" },
        { id: 4, name: "Organic" },
        { id: 5, name: "Sugar-free" },
        { id: 6, name: "Low Carb" },
        { id: 7, name: "Ketogenic" },
        { id: 8, name: "Kosher" },
        { id: 9, name: "Halal" },
        { id: 10, name: "Clean Eating" },
        { id: 11, name: "Lactose-free" },
        { id: 12, name: "Allergen-free" },
        { id: 13, name: "Paleo" },
      ],
      filteredSuggestions: [
        { id: 1, name: "Vegetarian" },
        { id: 2, name: "Vegan" },
        { id: 3, name: "Gluten-free" },
        { id: 4, name: "Organic" },
        { id: 5, name: "Sugar-free" },
        { id: 6, name: "Low Carb" },
        { id: 7, name: "Ketogenic" },
        { id: 8, name: "Kosher" },
        { id: 9, name: "Halal" },
        { id: 10, name: "Clean Eating" },
        { id: 11, name: "Lactose-free" },
        { id: 12, name: "Allergen-free" },
        { id: 13, name: "Paleo" },
      ],
      mirOptions: [
        { value: 0, name: "No rotation" },
        { value: 1, name: "Left" },
        { value: 2, name: "Right" },
        { value: 3, name: "Left-Right" },
      ],
      product: {
        description: null,
        name: null,
        published: null,
        temperature: null,
        weight: null,
        qrcode: {
          adp: null,
          len: null,
          mip: null,
          mir: null,
          miz: null,
          wbp: null,
          wfp: null,
          wft: null,
          wip: null,
          zdp: null,
        },
      },
      user: null,
    };
  },
  computed: {
    getPublishedValue() {
      if (this.product.published) return "Published";
      else return "Not published";
    },
  },
  created() {
    this.getUser();
  },
  mounted() {
    this.getProduct(this.id);
  },
  methods: {
    getUser() {
      Api.get("user/").then((response) => {
        this.user = response.data;
      });
    },
    getProduct(id) {
      Api.get("product/" + id + "/").then((response) => {
        this.product = response.data;
      });
    },
    filterSuggestions(search) {
      this.filteredSuggestions = this.suggestions.filter((suggestion) =>
        suggestion.name.toLowerCase().includes(search.toLowerCase())
      );
    },
    speichern() {
      // Frontend magic damit mehrfach selects als String gespeichert werden können
      if (Array.isArray(this.product.category)) {
        if (this.product.category.length === 1) {
          this.product.category = this.product.category[0].name;
        } else {
          let categoryString = this.product.category[0];
          this.product.category.shift();
          this.product.category.forEach((cat) => {
            categoryString += ", " + cat.name;
          });
          this.product.category = categoryString;
        }
      }

      Api.put("product/" + this.id + "/", this.product).then((response) => {
        if (response?.status === 202) {
          this.$notify({
            type: "error",
            title: "Action failed",
            text: `Product could not be saved. The name "${this.product.name}" already exists.`,
            duration: 10000,
          });
        } else {
          this.$notify({
            type: "success",
            title: "Action Successful",
            text: "Product successfully saved.",
            duration: 8000,
          });
          this.product = response.data;
        }
      });
    },
    publishProduct() {
      Api.put("product/", [this.id]).then((response) => {
        if (response?.status === 202) {
          this.$notify({
            type: "error",
            title: "Action failed",
            text: `Product could not be published. The name "${this.product.name}" already exists.`,
            duration: 10000,
          });
        } else {
          this.$notify({
            type: "success",
            title: "Action Successful",
            text: "Product successfully published.",
            duration: 8000,
          });
          this.getProduct(this.id);
        }
      });
    },
    async copyProduct() {
      let copy = JSON.parse(JSON.stringify(this.product));
      let copyId = null;

      copy.name += " [Copy]";
      copy.id = null;
      copy.published = false;

      await Api.post("product/", copy).then((response) => {
        if (response?.status === 202) {
          this.$notify({
            type: "error",
            title: "Action failed",
            text: `Product could not be copied. The name "${copy.name}" already exists.`,
            duration: 10000,
          });
        } else {
          copyId = response.data.id;

          this.$notify({
            type: "success",
            title: "Action successful",
            text: "Product successfully created.",
          });

          this.$router.push({
            name: "product",
            params: { id: copyId },
          });

          this.getProduct(copyId);
        }
      });
    },
    deleteProduct() {
      
      const isPublished = this.product.published;
      Api.delete("product/", { data: [this.id] }).then(() => {
        if (isPublished) this.$router.push({ name: "publishedProducts" });
        else this.$router.push({ name: "products" });
      });
    },
    openQRModal() {
      this.$bus.$emit("open-modal", "generate-qr-modal");
    },
    oeffneLoeschenModal() {
      this.$bus.$emit("open-modal", "loeschen-modal");
    },
    oeffneCopyModal() {
      this.$bus.$emit("open-modal", "copy-message-modal");
    },
    oeffnePublishModal() {
      this.$bus.$emit("open-modal", "message-modal");
    },
    generateQR(number, filetype) {
      if (this.loading) return;
      this.loading = true;
      Api.post(
        "qr-file/",
        {},
        {
          params: {
            filetype: filetype,
            product: this.product.qrcode.id,
            amount: number,
            published: this.product.published,
            company: this.user.company.id
          },
        }
      ).then((response) => {
        if(filetype == "zip"){
          this.downloadFile(response.data);
        }else if(filetype == "txt"){
          this.downloadTxtFile(response.data);
        } 
        
      })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadFile(fileURL) {
      const url = fileURL;

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "qr_codes.zip";
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    },
    downloadTxtFile(fileURL) {
      const url = fileURL;

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "qr_codes.txt";
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    },
  },
};
</script>

<style lang="scss"></style>
