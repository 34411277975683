<template>
  <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-white">
        <div class="modal-header bg-light-grey text-bold text-dark-blue p-3">
          <span>Generate QR-Codes</span>
          <modal-close-button @confirmed="closeModal" />
        </div>
        <div class="modal-body">
          <div class="row p-3">
            <div class="row mb-2 p-3">
              Please enter the amount of QR-Codes you want to generate.
            </div>
            <div class="row mb-2 p-3">
              <div class="col-12 p-0">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="number"
                    placeholder="Number of Codes"
                    v-model.trim="numberOfCodes"
                  />
                  <label>Number of Codes</label>
                </div>
                <span class="text-danger fs-12" v-show="!numberIsValid"
                  >This is not a valid number.</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-12 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-sm btn-grey mr-3"
                data-dismiss="modal"
                @click="closeModal"
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-dark-blue mr-3"
                @click="generateTxt"
              >
                <span>Generate .txt file</span>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-dark-blue"
                @click="generateZip"
              >
                <span>Generate .zip file</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
	
	<script>
import { modal } from "../../mixins/Modal";
import ModalCloseButton from "@/components/global/ModalCloseButton";

import { uuidv4 } from "@/utils/Uuid";

export default {
  name: "GenerateQRModal",
  components: {
    ModalCloseButton,
  },
  props: {
    id: {
      type: String,
      default: "generate-qr-modal",
    },
  },
  data() {
    return {
      numberOfCodes: 1,
      numberIsValid: true,
    };
  },
  mixins: [modal],
  computed: {},
  mounted() {},
  methods: {
    generateZip() {
      const number = parseInt(this.numberOfCodes);
      if (Number.isInteger(number) && number >= 0 && number <= 100000) {
        this.numberIsValid = true;
        this.$emit("generate", number, "zip");
        this.closeModal();
      } else {
        this.numberIsValid = false;
      }
    },
    generateTxt() {
      const number = parseInt(this.numberOfCodes);
      if (Number.isInteger(number) && number >= 0 && number <= 100000) {
        this.numberIsValid = true;
        this.$emit("generate", number, "txt");
        this.closeModal();
      } else {
        this.numberIsValid = false;
      }
    },
  },
};
</script>
	